:root {
  // Color-Colors
  --yellow: yellow;

  // Background
  --BASE-background: 0, 0%, 100%;
  --background: hsl(var(--BASE-background));
  --secondaryBackground: hsla(var(--BASE-label), 5%);
  --tertiaryBackground: hsl(var(--BASE-background));

  // Text
  --BASE-label: 0, 0%, 0%;
  --label: hsl(var(--BASE-label));
  --secondaryLabel: hsla(var(--BASE-label), 80%);
  --tertiaryLabel: hsla(var(--BASE-label), 60%);
  --quaternaryLabel: hsla(var(--BASE-label), 40%);
  --placeholder: hsla(var(--BASE-label), 20%);
  --link: RoyalBlue;
  --link-hover: rgb(128, 159, 255);

  // Separator
  --separator: hsla(var(--BASE-label), 10%);
  --opaqueSeparator: hsla(var(--BASE-label), 30%);

  // Shadow
  --shadow: 0px 2px 8px hsla(var(--BASE-label), 10%);

  @media (prefers-color-scheme: dark) {
    // Background
    --BASE-background: 0, 0%, 10%;
    --tertiaryBackground: hsla(var(--BASE-label), 10%);

    // Text
    --BASE-label: 0, 0%, 100%;

    // Separator
    --separator: hsla(var(--BASE-label), 15%);

    --link: Turquoise;
    --link-hover: hsl(203, 70%, 59%);
  }

  // Type Scale
  --display: 49px;
  --title: 39px;
  --subtitle: 31px;
  --heading: 25px;
  --subheading: 20px;
  --body: 16px;
  --caption: 13px;

  // Radius
  --radius-sm: 0.25em;
  --radius-md: 0.5em;
  --radius-lg: 1em;
  --radius-pill: 1000px;

  body {
    background: var(--background);
    font-size: var(--body);
    color: var(--label);
  }

  a {
    color: var(--link);
    text-decoration: none;
    &:hover {
      color: var(--link-hover);
      text-decoration: underline;
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500&display=swap");

:root {
  --brandFont: "Abhaya Libre", serif;
}

.message-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: none;
  align-items: flex-start;
  padding: 1em;

  .avatar {
    flex: none;
    width: 3em;
    height: 3em;
    margin-right: 0.5em;
    background: transparent url(./avatar.png) no-repeat;
    background-size: cover;
  }

  .message-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    line-height: 1.5;
    padding-right: 2em;

    .nickname {
      background: none;
      padding: 0;
      font-size: var(--caption);
      color: var(--tertiaryLabel);
    }

    p {
      border-radius: 0.5em;
      border-bottom-left-radius: 0;
      background-color: var(--secondaryBackground);
      padding: 0.5em 0.75em;
    }

    p + p {
      margin-top: 0.5em;
    }
  }
}

@media (hover: none) {
  .message-container {
    display: flex;
  }
  .App {
    display: none;
  }
}

.selector {
  margin: 1em 0;
  display: flex;
  justify-content: center;
  .item {
    padding: 0.5em;
    margin: 0 0.25em;
    border-radius: var(--radius-pill);
    text-align: center;
    cursor: pointer;
    border: 1px solid transparent;
    &.active {
      border: 1px solid var(--separator);
    }
    .ball {
      margin: 0 auto;
      width: 1.5em;
      height: 1.5em;
      border-radius: 100%;
      border: 1px solid var(--separator);
    }
  }
}

header {
  font-size: var(--subtitle);
  font-family: var(--brandFont);
  font-weight: 500;
  text-align: center;
  margin: 1em 0;
}

.color-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2em;

  padding: 3em 0;
  max-width: 1024px;
  margin: 0 auto;
  border-top: 1px solid var(--separator);
  border-bottom: 1px solid var(--separator);
}

.color {
  padding: 1em;
  color: var(--secondaryLabel);
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 100px;
  &:hover {
    background-color: var(--secondaryBackground);
  }

  .ball {
    cursor: zoom-in;
    margin-right: 1em;
    border-radius: 100%;
    width: 4em;
    height: 4em;
    border: 1px solid var(--separator);
    transition: all 300ms;
    &:hover {
      transform: scale(1.1);
    }
    &:active {
      transform: scale(1);
    }
  }

  .name {
    font-size: var(--subheading);
    font-family: var(--brandFont);
    font-weight: 400;
  }

  .caption {
    font-size: var(--caption);
    color: var(--quaternaryLabel);
    margin-top: 0.2em;
  }

  .hex {
    .label {
      padding-right: 0.4em;
      margin-right: 0.4em;
      border-right: 1px solid var(--separator);
    }
  }

  .name,
  .hex {
    transition: all 300ms;
    &:hover {
      transform: scale(1.05);
    }
    &:active {
      transform: scale(1);
    }
  }
}

footer {
  margin: 2em 0;
  color: var(--tertiaryLabel);
  text-align: center;

  .copyright {
    font-size: var(--caption);
    color: var(--quaternaryLabel);
    margin: 1em 0;
  }
}
